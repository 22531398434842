<script>
    import { Line } from "vue-chartjs";

    export default {
        extends: Line,
        props: {
            data: {
                type: Array || Object,
                defaults: null
            }, 
            usuarioWW: {
                type: Boolean, default: false
            }
        },
        data() {
            return {
                gradientChat: null,
                gradientFacebook: null,
                gradientEmail: null,
                gradientSMS: null,
                gradientTelefonia: null,
                gradientReceptiva: null,
                gradientTelegram: null,
                gradientWhatApp: null,
                gradientMailing: null,
                gradientWhatsWeb: null,
                gradientInstagram: null,

                label: this.data.map(list => {
                    return list.AtendimentoOrigem
                }),
                chatbot: this.data.map(list => {
                    return list.ChatBot
                }),
                email: this.data.map(list => {
                    return list.Email
                }),
                facebook: this.data.map(list => {
                    return list.Facebook
                }),
                sms: this.data.map(list => {
                    return list.SMS
                }),
                telefoniaReceptiva: this.data.map(list => {
                    return list.TelefoniaReceptiva
                }),
                telefoniaSimples: this.data.map(list => {
                    return list.TefoniaSimples
                }),
                telegram: this.data.map(list => {
                    return list.Telegram
                }),
                whatApp: this.data.map(list => {
                    return list.WhatApp
                }),
                whatsweb: this.data.map(list => {
                    return list.WhatsWeb
                }),
                instagram: this.data.map(list => {
                    return list.Instagram
                })
            };
        },
        mounted() {
            this.gradientFacebook = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientwhatApp = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientEmail = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientSMS = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientTelegram = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientTelefonia = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientReceptiva = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientChat = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientMailing = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientWhatsWeb = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradientInstagram = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

            this.gradientMailing.addColorStop(0, "rgba(52, 172, 224,1.0)");
            this.gradientMailing.addColorStop(0.5, "rgba(52, 172, 224,0.25)");
            this.gradientMailing.addColorStop(1, "rgba(75,0,130, 0)");

            this.gradientChat.addColorStop(0, "rgba(75,0,130,0.5)");
            this.gradientChat.addColorStop(0.5, "rgba(75,0,130, 0.25)");
            this.gradientChat.addColorStop(1, "rgba(75,0,130, 0)");

            this.gradientFacebook.addColorStop(0, "rgba(255, 0,0, 0.5)");
            this.gradientFacebook.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
            this.gradientFacebook.addColorStop(1, "rgba(255, 0, 0, 0)");

            this.gradientwhatApp.addColorStop(0, "rgba(72,61,139, 0.9)");
            this.gradientwhatApp.addColorStop(0.5, "rgba(72,61,139, 0.25)");
            this.gradientwhatApp.addColorStop(1, "rgba(72,61,139, 0)");

            this.gradientWhatsWeb.addColorStop(0, "rgba(72,61,139, 0.9)");
            this.gradientWhatsWeb.addColorStop(0.5, "rgba(72,61,139, 0.25)");
            this.gradientWhatsWeb.addColorStop(1, "rgba(72,61,139, 0)");

            this.gradientInstagram.addColorStop(0, "rgba(255,0,0, 0.9)");
            this.gradientInstagram.addColorStop(0.5, "rgba(255,0,0, 0.25)");
            this.gradientInstagram.addColorStop(1, "rgba(255,0,0, 0)");

            this.gradientEmail.addColorStop(0, "rgba(0,139,139, 0.5)");
            this.gradientEmail.addColorStop(0.5, "rgba(0,139,139, 0.25)");
            this.gradientEmail.addColorStop(1, "rgba(0,139,139, 0)");

            this.gradientSMS.addColorStop(0, "rgba(255,255,0, 0.5)");
            this.gradientSMS.addColorStop(0.5, "rgba(255,255,0, 0.25)");
            this.gradientSMS.addColorStop(1, "rgba(255,255,0, 0)");


            this.gradientTelegram.addColorStop(0, "rgba(105,105,105, 0.5)");
            this.gradientTelegram.addColorStop(0.5, "rgba(105,105,105, 0.25)");
            this.gradientTelegram.addColorStop(1, "rgba(105,105,105, 0.0)");

            this.gradientTelefonia.addColorStop(0, "rgba(128,0,0, 0.5)");
            this.gradientTelefonia.addColorStop(0.5, "rgba(128,0,0, 0.25)");
            this.gradientTelefonia.addColorStop(1, "rgba(128,0,0, 0.0)");

            this.gradientReceptiva.addColorStop(0, "rgba(255,105,180, 0.5)");
            this.gradientReceptiva.addColorStop(0.5, "rgba(255,105,180, 0.25)");
            this.gradientReceptiva.addColorStop(1, "rgba(255,105,180, 0.0)");
            if (!this.usuarioWW) {
                this.renderChart(
                    {
                        labels: this.label,
                        datasets: [
                            {
                                label: 'Chatbot',
                                borderColor: "#4B0082",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientChat,
                                data: this.chatbot

                            },

                            {
                                label: 'Email',
                                borderColor: "#008B8B",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientEmail,
                                data: this.email
                            },
                            {
                                label: 'Facebook',
                                borderColor: "#FF0000",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientFacebook,
                                data: this.facebook
                            },
                            {
                                label: 'SMS',
                                borderColor: "#FFFF00",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientSMS,
                                data: this.sms
                            },
                            {
                                label: 'Telefonia Receptiva',
                                borderColor: "#FF69B4",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientReceptiva,
                                data: this.telefoniaReceptiva
                            },
                            {
                                label: 'Atendimento Ativo',
                                borderColor: "#BD7F7F",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientTelefonia,
                                data: this.telefoniaSimples
                            },
                            {
                                label: 'Telegram',
                                borderColor: "#B1B1B1",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientTelegram,
                                data: this.telegram
                            },
                            {
                                label: 'WhatApp',
                                borderColor: "#483D8B",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientWhatApp,
                                data: this.whatApp
                            },
                            {
                                label: 'WhatsWeb',
                                borderColor: "#483D8B",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientWhatsWeb,
                                data: this.whatsweb
                            },
                            {
                                label: 'Instagram',
                                borderColor: "#f00",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientInstagram,
                                data: this.instagram
                            },

                        ]
                    },
                    { responsive: true, maintainAspectRatio: false }
                );
            } else {

                this.renderChart(
                    {
                        labels: this.label,
                        datasets: [
                            {
                                label: 'WhatsWeb',
                                borderColor: "#483D8B",
                                pointBackgroundColor: "white",
                                borderWidth: 1,
                                pointBorderColor: "white",
                                backgroundColor: this.gradientWhatsWeb,
                                data: this.whatsweb
                            },
                            

                        ]
                    },
                    { responsive: true, maintainAspectRatio: false }
                );
            }//fim else
            
        }
    };
</script>