<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
   props:{
       data:{
          type:Array || Object,
          defaults:null
        }            
  },    
  data(){
      return {
        gradientChat:      null,
        gradientFacebook:  null,
        gradientEmail:     null,     
        gradientSMS:       null,
        gradientTelefonia: null,
        gradientReceptiva: null,
        gradientTelegram:  null,
        gradientWhatApp:   null,
        gradientMailing:   null,
        GrandientCores:   null,
      }
  },
  methods:{
      metodos(){
                
                if (this.data.length == 0)
                {                   
                    this.dados = [100];
                    this.origem = ["Sem atendimentos"];
                    return this.GrandientCores=[];
                }
              
              // seta as corres que vao aparecer e em que ordem
              this.gradientCores =[              
              this.gradientEmail, 
              this.gradientChat,
              this.gradientMailing,
              this.gradientReceptiva,
              this.gradientTelefonia,
              this.gradientTelegram,
              this.gradientSMS,
              this.gradientFacebook,             
              this.gradientWhatApp,             
              "#00D8FF"],

              //mapeiam os dados que chegam jogando em suas respectivos arrays
              this.dados= this.data.map(list =>{                      
                              return list.Atendimentos            
               }),
              this.origem= this.data.map(list =>{  
                               return list.AtendimentoOrigem             
              }) 
        }
  },
  mounted() {  
           
    this.gradientFacebook= this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientwhatApp= this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientEmail= this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientSMS= this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientTelegram= this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientTelefonia= this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientReceptiva = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);   
    this.gradientChat = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
    this.gradientMailing = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

    //representação de dados vazio
    this.gradientMailing.addColorStop(0, "rgba(52, 172, 224,1.0)");
    this.gradientMailing.addColorStop(0.5, "rgba(52, 172, 224,0.25)");
    this.gradientMailing.addColorStop(1, "rgba(75,0,130, 0)");

    this.gradientChat.addColorStop(0, "rgba(75,0,130,0.5)");
    this.gradientChat.addColorStop(0.5, "rgba(75,0,130, 0.25)");
    this.gradientChat.addColorStop(1, "rgba(75,0,130, 0)");

    this.gradientFacebook.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradientFacebook.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradientFacebook.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradientwhatApp.addColorStop(0, "rgba(72,61,139, 0.9)");
    this.gradientwhatApp.addColorStop(0.5, "rgba(72,61,139, 0.25)");
    this.gradientwhatApp.addColorStop(1, "rgba(72,61,139, 0)");

    this.gradientEmail.addColorStop(0, "rgba(0,139,139, 0.5)");
    this.gradientEmail.addColorStop(0.5, "rgba(0,139,139, 0.25)");
    this.gradientEmail.addColorStop(1, "rgba(0,139,139, 0)");

    this.gradientSMS.addColorStop(0, "rgba(255,255,0, 0.5)");
    this.gradientSMS.addColorStop(0.5, "rgba(255,255,0, 0.25)");
    this.gradientSMS.addColorStop(1, "rgba(255,255,0, 0)");


    this.gradientTelegram.addColorStop(0, "rgba(105,105,105, 0.5)");
    this.gradientTelegram.addColorStop(0.5, "rgba(105,105,105, 0.25)");
    this.gradientTelegram.addColorStop(1, "rgba(105,105,105, 0.0)");
    
    this.gradientTelefonia.addColorStop(0, "rgba(128,0,0, 0.5)");
    this.gradientTelefonia.addColorStop(0.5, "rgba(128,0,0, 0.25)");
    this.gradientTelefonia.addColorStop(1, "rgba(128,0,0, 0.0)");

    this.gradientReceptiva.addColorStop(0, "rgba(255,105,180, 0.5)");
    this.gradientReceptiva.addColorStop(0.5, "rgba(255,105,180, 0.25)");
    this.gradientReceptiva.addColorStop(1, "rgba(255,105,180, 0.0)");

    this.metodos();   
    this.renderChart(
      {
        labels: this.origem,
        datasets: [
          {
            backgroundColor:this.gradientCores,
            data: this.dados
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
};
</script>