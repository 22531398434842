<script>
import { Doughnut } from 'vue-chartjs'
  export default {    
    extends: Doughnut,
    props:{
      label:{
        type:Array||String,
        defaults:null
      },
      dados:{
        type:Array||Object,
        defaults:null
      },
      responsive:{
         type:Boolean,
         default:true
      }
    },
    data () {
      return {
      
        chartData: {
         
          labels: this.dados.reduce( (total,numero) => total + numero,0) > 0 ? this.label :  ['Sem informações'],
          datasets: [{
              borderWidth: 0,
              borderColor:[ 'rgba(52, 172, 224,1.0)',
                            'rgba(51, 217, 178,1.0)',
                            'rgba(112, 111, 211,1.0)',
                            'rgba(44, 44, 84,1.0)',
                            'rgba(255, 82, 82,1.0)',
                            'rgba(255, 177, 66,1.0)',
                            'rgba(132, 129, 122,1.0)',
                            'rgba(204, 142, 53,1.0)',
                            'rgba(255, 218, 121,1.0)',
                          ],
              backgroundColor: this.dados.reduce( (total,numero) => total + numero,0) === 0 ? [] :    
                            ['rgba(52, 172, 224,1.0)',
                            'rgba(51, 217, 178,1.0)',
                            'rgba(112, 111, 211,1.0)',
                            'rgba(44, 44, 84,1.0)',
                            'rgba(255, 82, 82,1.0)',
                            'rgba(255, 177, 66,1.0)',
                            'rgba(132, 129, 122,1.0)',
                            'rgba(204, 142, 53,1.0)',
                            'rgba(255, 218, 121,1.0)',
              ],
              data: this.dados.reduce( (total,numero) => total + numero,0) == 0 ? [100] : this.dados,
            }]
        },
        options: {
          legend: {
                display: true,
          },
          responsive: this.responsive,
          maintainAspectRatio: false
        }
      }
    },
    computed:{
      
    },
   
    methods:{
      
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>