<template>
    <!--<div class="cartao-dashboard" v-on:click="$emit('click')" >-->
    <div class="cartao-dashboard"
         @click="$emit('modalAllAtendimento', permiteClick, origem, link)">
        <div class="conteudo-container">
            <p id="cartao-titulo">{{titulo}}</p>
            <span id="cartao-subtitulo">{{subtitulo}}</span><br />
        </div>
        <!-- <router-link v-if="link"
                     to="/relatorios"
                     style="font-size:11px;">Consultar todos </router-link> -->
    </div>
</template>

<script>
    export default {
        name: 'CartaoDashboard',
        props: {
            classe: String,
            titulo: String,
            subtitulo: String,
            permiteClick: Boolean,
            link: Boolean,
            origem: String
        },
    }
</script>

